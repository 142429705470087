<template>
  <div v-if="apiToken">
    Display BAK Here...
  </div>
</template>

<script>
export default {
  name: 'BAK',
  data: function () {
    return {
    }
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
</style>
